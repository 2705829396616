import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import { SelectModal } from "components/flink-components";
import classes from "./MultiLocaleControls.module.scss";

import { formatLocalesToOptions } from "utils";

const MultiLocaleControls = ({
  selectedLocale,
  translate,
  locales,
  classes: propsClasses = {},
  multiLocaleData,
  stringData,
  changeLocaleHandler,
  makeDefaultHandler,
  makeSingleHandler,
  makeMultiHandler,
  makeMultiDialogText,
  makeSingleDialogText,
  isTranslator
}) => {
  const [selectLocaleModalOptions, setSelectLocaleModalOptions] = useState(
    null
  );

  const localesOptions = useMemo(() => formatLocalesToOptions(locales), [
    locales
  ]);

  function askLocale(message, availableLocales) {
    return new Promise((resolve, reject) => {
      setSelectLocaleModalOptions({
        options: availableLocales,
        html: message,
        cb: resolve
      });
    });
  }

  function onMakeMultiClick() {
    if (!isTranslator) {
      askLocale(makeMultiDialogText, localesOptions).then(locale => {
        makeMultiHandler(locale);
        setSelectLocaleModalOptions(null);
      });
    }
    else{
      console.log('MultiLocaleControls selectedLocale: ',selectedLocale);
      makeMultiHandler(selectedLocale);
    }
  }

  function onMakeSingleClick() {
    if (!isTranslator) {
      askLocale(makeSingleDialogText, localesOptions).then(locale => {
        makeSingleHandler(locale);
        setSelectLocaleModalOptions(null);
      });
    }
    else{
      console.log('MultiLocaleControls selectedLocale: ',selectedLocale);
      makeSingleHandler(selectedLocale);
    }
  }

  const isMultiLocale = !!multiLocaleData;

  const isDefaultChecked =
    !!isMultiLocale && multiLocaleData.defaultLocale === selectedLocale;

  return (
    <>
      <div className={classnames(classes.wrapper, propsClasses.wrapper)}>
        {isMultiLocale ? (
          <>
          {!isTranslator && 
            <div className={classnames(classes.select, propsClasses.select)}>
              <label>{translate(236, "Language:")}</label>

              <select
                value={selectedLocale}
                onChange={e => changeLocaleHandler(e.target.value)}
              >
                {localesOptions.map(opt => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>

              <label
                className={classnames(classes.checkbox, propsClasses.checkbox)}
              >
                <input
                  type="checkbox"
                  disabled={isDefaultChecked}
                  onChange={makeDefaultHandler}
                  checked={isDefaultChecked}
                />{" "}
                <span>{translate(264, "Default")}</span>
              </label>
            </div>
          }

            <button
              className={classnames(classes.button, propsClasses.button)}
              onClick={onMakeSingleClick}
            >
              {!isTranslator 
                ? translate(263, "Make single-locale") 
                : translate(1417, "Make String Help")
              }
            </button>
          </>
        ) : (
          <button
            className={classnames(classes.button, propsClasses.button)}
            onClick={onMakeMultiClick}
            disabled={!!stringData}
          >
            {!isTranslator 
              ? translate(250, "Make multi-locale")
              : translate(1418, "Make Multi Lang Help")
            }
          </button>
        )}
      </div>

      {selectLocaleModalOptions && !isTranslator &&(
        <SelectModal
          show
          title={translate(251, "Select a locale")}
          options={selectLocaleModalOptions.options}
          selectHandler={selectLocaleModalOptions.cb}
          html={selectLocaleModalOptions.html}
          onClose={() => setSelectLocaleModalOptions(null)}
        />
      )}
    </>
  );
};

MultiLocaleControls.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func.isRequired,
  changeLocaleHandler: PropTypes.func.isRequired,
  locales: PropTypes.array.isRequired,
  multiLocaleData: PropTypes.object,
  stringData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  makeDefaultHandler: PropTypes.func.isRequired,
  makeSingleHandler: PropTypes.func.isRequired,
  makeMultiHandler: PropTypes.func.isRequired,
  isTranslator: PropTypes.bool,
  selectedLocale: PropTypes.string,
};

const mapStateToProps = ({ status, common }) => ({
  translate: status.translate,
  locales: common.locales
});

export default connect(mapStateToProps)(MultiLocaleControls);
