import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import { AddImageBlock, ActivityAddAudio } from "components/flink-components";
import { StringAssignField } from "components/form-components";
import classes from "./ItemConfigurator.module.scss";
import { changeGameData, removeContentFiles, changeLanguageVersionData } from "actions/flinkMakeActions";
import { imagesURL } from "config";
import { isUsingStrings } from "utils";
import useGetStringAudio from "utils/hooks/useGetStringAudio";
import { withTranslate } from "components/hocs";

const ItemConfigurator = ({
  removeContentFiles,
  activeItem,
  translate,
  selectedItemPath,
  changeGameData,
  activeProblemIdx,
  withoutText,
  withoutImage,
  withoutAudio,
  textAreaWidth,
  maxLetters,
  changeLanguageVersionData,
  versionType
}) => {
  let stringNumber, audioPath; // for strings
  let { text, image, audio } = activeItem || {};

  const textareaRef = useRef();
  const getStringAudio = useGetStringAudio();

  const usingStrings = isUsingStrings(text);
  if (usingStrings) {
    const stringAudio = getStringAudio(text);
    text = translate(text, '', true);
    
    stringNumber = stringAudio.stringNumber;
    audio = stringAudio.audio;
    audioPath = stringAudio.path;
  }

  useEffect(() => {
    if (
      !image &&
      textareaRef.current &&
      document.activeElement.id !== "problem-list"
    ) {
      // Move caret in textarea to the end
      const temp = textareaRef.current.value;
      textareaRef.current.value = "";
      textareaRef.current.value = temp;
      textareaRef.current.focus();
    }
  }, [activeProblemIdx, selectedItemPath, image]);

  return (
    <div className={classes.wrapper}>
      {!withoutText && (
        <div className={classes.textareaWrapper}>
          <label className={classes.label}>{translate(18, "Add Text")}</label>
          <textarea
            tabIndex="-1"
            ref={textareaRef}
            className={classes.textarea}
            value={text || ""}
            style={{ width: textAreaWidth }}
            maxLength={maxLetters}
            onChange={e => {
              if (versionType) 
                changeLanguageVersionData(
                  `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.text`,
                  e.target.value
                );
              else
                changeGameData(
                  `problems[${activeProblemIdx}].${selectedItemPath}.text`,
                  e.target.value
                );

              if (image) {
                if (versionType) 
                  changeLanguageVersionData(
                    `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.image`,
                    null
                  );
                else
                  changeGameData(
                    `problems[${activeProblemIdx}].${selectedItemPath}.image`,
                    null
                  );
                removeContentFiles([image]);
              }
            }}
            readOnly={usingStrings}
          />

          <StringAssignField
            value={stringNumber}
            changeAudioHandler={filename => {
              if (versionType) 
                changeLanguageVersionData(
                  `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.audio`,
                  filename
                );
              else
                changeGameData(
                  `problems[${activeProblemIdx}].${selectedItemPath}.audio`,
                  filename
                );
            }}
            changeTextHandler={text => {
              if (versionType) 
                changeLanguageVersionData(
                  `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.text`,
                  text
                );
              else
                changeGameData(
                  `problems[${activeProblemIdx}].${selectedItemPath}.text`,
                  text
                );
            }}
            withoutAudio={withoutAudio}
            isTooltip
          />

          {!usingStrings && text && (
            <button
              className={classes.deleteBtn}
              onClick={() => {
                if (versionType) 
                  changeLanguageVersionData(
                    `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.text`,
                    null
                  );
                else
                  changeGameData(
                    `problems[${activeProblemIdx}].${selectedItemPath}.text`,
                    null
                  );
              }}
            >
              <img
                src={`${imagesURL}/FrontendUtilities/delete1.png`}
                alt="delete"
              />
            </button>
          )}
        </div>
      )}

      {!withoutImage && (
        <div className={classes.imageWrapper}>
          <AddImageBlock
            withOverlay
            withId
            pathToProp={versionType 
              ? `languageVersionData.${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.image`
              : `gameData.problems[${activeProblemIdx}].${selectedItemPath}.image`
            }
            versionType={versionType}
            withoutPreview
            onChangeHandler={() => {
              if (!text) return;
              if (versionType) 
                changeLanguageVersionData(
                  `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.text`,
                  null
                );
              else
                changeGameData(
                  `problems[${activeProblemIdx}].${selectedItemPath}.text`,
                  null
                );
            }}
          />
        </div>
      )}

      {!withoutAudio && (
        <div className={classes.audioWrapper}>
          <ActivityAddAudio
            current={audio}
            path={audioPath}
            versionType={versionType}
            onChangeHandler={filename => {
              if (versionType) 
                changeLanguageVersionData(
                  `${versionType}.problems[${activeProblemIdx}].${selectedItemPath}.audio`,
                  filename
                );
              else
                changeGameData(
                  `problems[${activeProblemIdx}].${selectedItemPath}.audio`,
                  filename
                );
            }}
            associatedText={text}
            readOnly={usingStrings}
            useFileBucket={usingStrings}
          />
        </div>
      )}
    </div>
  );
};

ItemConfigurator.propTypes = {
  activeItem: PropTypes.object,
  selectedItemPath: PropTypes.string,
  changeGameData: PropTypes.func.isRequired,
  activeProblemIdx: PropTypes.number,
  changeLanguageVersionData: PropTypes.func.isRequired,
  versionType: PropTypes.string
};

export default compose(
  withTranslate,
  connect(null, { changeGameData, removeContentFiles, changeLanguageVersionData })
)(ItemConfigurator);
